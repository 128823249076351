// @flow
import Bugsnag from '@bugsnag/js'
import styled from '@emotion/styled'
import { useAuth, useSession } from '@toggl/auth'
import { colors, media } from '@toggl/style'
import { Form, Button, Hero, text, Icon, Link as UiLink } from '@toggl/ui'
import { useFormik } from 'formik'
import { Link } from 'gatsby'
import React from 'react'
import LayoutTrack from 'components/LayoutTrack'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import videoSrc from 'images/uploads/bg-video.mp4'
import IndexLayout from 'layouts'

import type { PageProps } from 'gatsby'
import type { PricingPlan } from '@toggl/auth/src/types'

const validate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'invalid email address'
  }

  if (!values.password) {
    errors.password = 'required'
  } else if (values.password.length < 8) {
    errors.password = 'must be 8 characters or more'
  } else if (
    !values.password.match(/[a-z]/) ||
    !values.password.match(/[A-Z]/)
  ) {
    errors.password = 'lowercase and uppercase letters'
  } else if (!values.password.match(/[0-9]/)) {
    errors.password = 'at least one number'
  }

  if (!values.country) {
    errors.country = 'required'
  }

  if (!values.tos_accepted) {
    errors.tos_accepted = 'you must agree in order to use Toggl Track'
  }

  return errors
}

const DEFAULT_TIMEZONE = 'Etc/UTC'

const HeroComponent = ({ children }) => {
  return (
    <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
      {children}
      <StyledWrapper>
        <SmallTitle>Get Tracking</SmallTitle>
        <Hero.Title centered>Let's get started</Hero.Title>
      </StyledWrapper>
    </Hero.Wrapper>
  )
}

const SignupPage = ({
  pageContext: { planId },
}: PageProps<null, { planId: PricingPlan }>) => {
  const [countries, setCountries] = React.useState(null)
  const [location, setLocation] = React.useState(null)
  const [ready, auth] = useAuth()
  const [, session] = useSession()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      tos_accepted: false,
      created_with: 'Firefly',
      country: '',
      timezone: '',
      workspace: { initial_pricing_plan: planId },
    },
    validate,
    validateOnChange: false,
    // $FlowFixMe useFormik type definition is outdated
    onSubmit: async ({ country, ...rest }) => {
      const values = { ...rest, country: parseInt(country) }

      try {
        const { id } = await auth.signup(values)
        session.create(id)
        window.location.assign(process.env.GATSBY_TRACK_APP_URL)
      } catch (e) {
        if (e.meta?.status === 400) {
          formik.setFieldError('form', e.message)
        } else {
          formik.setFieldError(
            'form',
            "We couldn't create your account right now. Please try again in a few minutes."
          )
          Bugsnag.notify(e, (event) => {
            event.addMetadata('xhr', { ...e.meta })
          })
        }
      }
    },
  })

  React.useEffect(() => {
    if (!ready || formik.values.timezone) {
      return
    }

    const supposedTz = Intl.DateTimeFormat().resolvedOptions().timeZone
    auth
      .timezones()
      .then((timezones) => {
        formik.setFieldValue(
          'timezone',
          timezones.includes(supposedTz) ? supposedTz : DEFAULT_TIMEZONE
        )
        return timezones
      })
      .catch((e) => formik.setFieldError('form', e.message))
  }, [auth, formik, ready])

  React.useEffect(() => {
    if (!ready || countries != null) {
      return
    }

    auth
      .countries()
      .then((countries) => {
        setCountries(countries)
        return countries
      })
      .catch((e) => formik.setFieldError('form', e.message))
  }, [auth, countries, formik, ready])

  React.useEffect(() => {
    if (!ready || location) {
      return
    }

    auth
      .location()
      .then((location) => {
        setLocation(location)
        return location
      })
      .catch((e) => formik.setFieldError('form', e.message))
  }, [auth, formik, ready, location])

  React.useEffect(() => {
    if (!location || !countries || formik.values.country) {
      return
    }

    const country = countries.find(
      (c) => c.country_code === location.country_code
    )

    if (country) {
      formik.setFieldValue('country', country.id.toString())
    }
  }, [location, countries, formik])

  const appleSignup = React.useCallback(async () => {
    formik.setSubmitting(true)
    try {
      const url = await auth.appleSignUp(
        parseInt(formik.values.country),
        planId
      )

      window.location.assign(url)
    } catch (err) {
      formik.setFieldError(
        'form',
        "Sorry, we couldn't sign you up with Apple. Please choose another method or contact support."
      )
    } finally {
      formik.setSubmitting(false)
    }
  }, [auth, formik, planId])

  const googleSignup = React.useCallback(async () => {
    formik.setSubmitting(true)
    try {
      const url = await auth.googleSignUp(
        parseInt(formik.values.country),
        planId
      )

      window.location.assign(url)
    } catch (err) {
      formik.setFieldError(
        'form',
        "Sorry, we couldn't sign you up with Google. Please choose another method or contact support."
      )
    } finally {
      formik.setSubmitting(false)
    }
  }, [auth, formik, planId])

  return (
    <IndexLayout>
      <LayoutTrack Hero={HeroComponent}>
        <Root>
          <StyledForm error={formik.errors.form} onSubmit={formik.handleSubmit}>
            <Form.Select
              id="country"
              label={<label htmlFor="country">Country</label>}
              name="country"
              placeholder={'Country'}
              onChange={formik.handleChange}
              value={formik.values.country}
              error={
                formik.errors.country ? (
                  <div>{formik.errors.country}</div>
                ) : null
              }
            >
              {countries && countries.length
                ? countries.map((country) => (
                    <option key={country.id.toString()} value={country.id}>
                      {country.name}
                    </option>
                  ))
                : []}
            </Form.Select>
            <SocialSignupWrapper>
              <Form.TwoFieldWrapper>
                <Button.SocialLogin
                  type="button"
                  disabled={!ready || formik.isSubmitting}
                  onClick={googleSignup}
                >
                  <Icon.Google />
                  Sign up via Google
                </Button.SocialLogin>
                <Button.SocialLogin
                  type="button"
                  disabled={!ready || formik.isSubmitting}
                  onClick={appleSignup}
                >
                  <Icon.Apple />
                  Sign up via Apple
                </Button.SocialLogin>
              </Form.TwoFieldWrapper>
              <StyledCheckbox
                id="tos_accepted"
                error={
                  formik.errors.tos_accepted ? (
                    <div>{formik.errors.tos_accepted}</div>
                  ) : null
                }
                onChange={formik.handleChange}
              >
                <span>
                  I agree to the{' '}
                  <Link to={'/legal'}>
                    <UiLink.Underlined>terms of service</UiLink.Underlined>
                  </Link>{' '}
                  and{' '}
                  <Link to={'/legal'}>
                    <UiLink.Underlined>privacy policy.</UiLink.Underlined>
                  </Link>
                </span>
              </StyledCheckbox>
            </SocialSignupWrapper>

            <Form.Input
              id="email"
              label={<label htmlFor="email">Email</label>}
              name="email"
              type="email"
              placeholder={'Email'}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={
                formik.errors.email ? <div>{formik.errors.email}</div> : null
              }
            />

            <Form.Input
              id="password"
              label={<label htmlFor="password">Password</label>}
              name="password"
              type="password"
              placeholder={'Password'}
              onChange={formik.handleChange}
              value={formik.values.password}
              error={
                formik.errors.password ? (
                  <div>{formik.errors.password}</div>
                ) : null
              }
            />

            <SsoWrapper>
              <SsoButton>
                <Icon.Lock />
                Company sign up (SSO)
              </SsoButton>
            </SsoWrapper>
            <Button.Primary
              type="submit"
              disabled={!ready || formik.isSubmitting}
            >
              Submit
            </Button.Primary>
          </StyledForm>

          <SignupCta>
            <span>Have an account already?</span>
            <Link to={'/track/login'}>
              <Button.Primary>Login</Button.Primary>
            </Link>
          </SignupCta>
        </Root>
      </LayoutTrack>
    </IndexLayout>
  )
}
const StyledCheckbox = styled(Form.Checkbox)`
  margin-top: 25px;
`
const SocialSignupWrapper = styled.div`
  padding-bottom: 30px;
`
const SsoWrapper = styled.div``
const SsoButton = styled(Button.SocialLogin)`
  background: transparent;
  color: ${colors.beige};
  svg {
    width: 20px;
    margin-right: 10px;
  }
`
const StyledWrapper = styled(Hero.ContentWrapper)`
  ${media.mq[1]} {
    padding: 76px 20px 168px 20px;
  }
`

const Root = styled.div`
  padding-bottom: 130px;
  background: ${colors.fadedPurple};
`

const SignupCta = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span {
    ${text.paragraph2};
    color: ${colors.almostWhite};
    margin-bottom: 30px;
  }
`

const SmallTitle = styled.div`
  ${text.paragraph2};
  color: ${colors.almostWhite};
  text-align: center;
`

const StyledForm = styled(Form.Wrapper)`
  padding: 26px 16px;
  ${media.mq[1]} {
    width: 673px;
    padding: 58px 45px 64px 45px;
    background-color: ${colors.darkPurple};
    margin: 0 auto;
    transform: translateY(-30px);
    z-index: 200;
    position: relative;
  }
`

export default SignupPage
